import React, { useState, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { MilkdownProvider } from "@milkdown/react";
import { StrictMode } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "~/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "~/components/ui/accordion";
import { Input } from "~/components/ui/input";
import { CoverageReportEditor } from "./CoverageReportEditor";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import {
  DocumentTypeEnum,
  SingleDocResponseData,
} from "~/api/query_fns/documents";
import { openReferenceType } from "~/doc-util";
import { Pencil } from "lucide-react";
import { Message } from "../components/CustomMessages";
import { useNavigate } from "react-router-dom";
import {
  ArtifactFetchResponse,
  regenerateWithAdditions,
  ReportType,
} from "~/api/query_fns/coverage-analysis";
import { updateReport } from "~/api/query_fns/coverage-analysis";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "~/components/ui/dialog";
import { Textarea } from "~/components/ui/textarea";
import { Button } from "~/components/ui/button";
import AdditionalInformation from "./AdditionalInformation";
import { ReportHorizontalMenu } from "./ReportHorizontalMenu";
import { exportReportToPDF, copyShareableLink } from "~/utils/ReportMenuHelper";
import CustomDocViewer from "./DocViewer";
import { useDocumentUpload } from "~/hooks/useGenerateReportNew";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import ReportQumisInsights from "./report/ReportQumisInsights";

export function ReportTabs({
  reportId,
  reportName,
  fullCoverageReport,
  docs,
  openReference,
  reportChatMessages,
  reportType,
  documents,
  initialRating = 0,
  version = 0,
  selectedText = "",
  coverageReportUrl,
  artifacts,
}: {
  reportId: string;
  reportName: string;
  fullCoverageReport: string;
  docs: SingleDocResponseData[];
  openReference: openReferenceType;
  reportChatMessages: Message[];
  reportType: ReportType;
  documents: SingleDocResponseData[];
  initialRating?: number;
  version?: number;
  selectedText?: string;
  coverageReportUrl?: string;
  artifacts?: ArtifactFetchResponse[];
}) {
  const [editableReportName, setEditableReportName] = useState(reportName);
  const [displayReportName, setDisplayReportName] = useState(reportName);
  const [isEditing, setIsEditing] = useState(false);
  const [rating, setRating] = useState(initialRating);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const navigate = useNavigate();
  const [showAddFilesDialog, setAddFilesDialogOpen] = useState(false);
  const [newDocumentIds, setNewDocumentIds] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [showQumisInsights, setShowQumisInsights] = useState(false);

  const documentNames = documents.map((doc) => doc.document.filename);

  const {
    handleUploadComplete: baseHandleUploadComplete,
    handleDeleteUploadedDoc: baseHandleDeleteUploadedDoc,
  } = useDocumentUpload();

  // Wrap the base handlers to include the newDocumentIds state
  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    baseHandleUploadComplete(docResponse);
    setNewDocumentIds((prev) => [...prev, docResponse.document.id]);
  };

  const handleDeleteUploadedDoc = (docId: string) => {
    baseHandleDeleteUploadedDoc(docId);
    setNewDocumentIds((prev) => prev.filter((id) => id !== docId));
  };

  // Update display name when component mounts or version changes
  useEffect(() => {
    setDisplayReportName(`${reportName}  (V${version})`);
  }, [reportName, version]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setEditableReportName(newName);
    setDisplayReportName(`${newName} V${version}`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    updateReport({
      id: reportId || "",
      report_name: editableReportName, // Save without version number
    });
  };

  const handleRating = async (rate: number) => {
    if (rate !== rating && reportId) {
      setRating(rate);

      try {
        const response = await updateReport({
          id: reportId,
          rating: rate,
        });

        if (response.success) {
          if (rate < 5) {
            setShowFeedbackDialog(true);
          } else {
            toast.success(`Thank you for your feedback`, {
              position: "top-center",
              style: {
                border: "1px solid #000000",
                padding: "16px",
                color: "#000000",
              },
              iconTheme: {
                primary: "#000000",
                secondary: "#FFFAEE",
              },
            });
          }
        } else {
          throw new Error(
            response.errors?.join(", ") || "Failed to update rating"
          );
        }
      } catch (error) {
        console.error("Error updating rating:", error);
        toast.error("Failed to update rating. Please try again later.");
      }
    } else {
      console.log("Rating unchanged or reportId missing:", { reportId, rate });
    }
  };

  const handleFeedbackSubmit = async () => {
    // Here you can add logic to submit the feedback
    console.log("Feedback submitted:", feedbackText);

    await updateReport({
      id: reportId || "",
      feedback: feedbackText,
    });

    setShowFeedbackDialog(false);
    setShowRegenerateDialog(false);

    // Show success toast
    toast.success(`Thank you for your feedback`, {
      position: "top-center",
      style: {
        border: "1px solid #000000",
        padding: "16px",
        color: "#000000",
      },
      iconTheme: {
        primary: "#000000",
        secondary: "#FFFAEE",
      },
    });
  };

  const handleRegenerateReport = async () => {
    if (!reportId) {
      console.error("Report ID is missing");
      return;
    }

    try {
      // Close the dialog first
      setShowFeedbackDialog(false);
      setShowRegenerateDialog(false);

      // Wait a short time to ensure the dialog has time to close
      await new Promise((resolve) => setTimeout(resolve, 300));

      await updateReport({
        id: reportId,
        feedback: feedbackText,
      });

      setRating(0);
      setFeedbackText("");

      const response = await regenerateWithAdditions({
        report_id: reportId,
        regenerate_prompt: feedbackText,
      });

      // Navigate after all operations are complete
      navigate(`/report/analysis/${response.jobId}?status=regen`);
    } catch (error) {
      console.error("Error regenerating report:", error);
      toast.error("Failed to regenerate report. Please try again later.");
    }
  };

  const handleSubmitAdditionalFiles = async () => {
    if (!reportId) {
      console.error("Report ID is missing");
      return;
    }

    const response = await regenerateWithAdditions({
      report_id: reportId,
      regenerate_prompt: feedbackText,
      new_document_ids: newDocumentIds.join(","),
    });

    // Use a small delay to ensure the dialog is closed before navigation
    setTimeout(() => {
      navigate(`/report/analysis/${response.jobId}?status=update`);
    }, 100);
    setAddFilesDialogOpen(false);
  };

  const handleRegenerateDialogClose = () => {
    setShowRegenerateDialog(false);
  };

  const handleRegenerateDialogOpen = () => {
    setShowRegenerateDialog(true);
  };

  const handleAddFilesDialogOpen = () => {
    setAddFilesDialogOpen(true);
  };

  const handleAddFilesCloseDialog = () => {
    setAddFilesDialogOpen(false);
  };

  const handleExportPDF = () => {
    if (reportType === "coverage_checklist" && coverageReportUrl) {
      // For coverage checklist, directly download the PDF from the URL
      window.open(coverageReportUrl, "_blank");
    } else if (fullCoverageReport || reportChatMessages) {
      // For other report types, use the existing export logic
      exportReportToPDF(
        reportId,
        fullCoverageReport,
        displayReportName,
        reportChatMessages,
        documentNames || []
      );
    }
  };

  const lastChatMessage =
    reportChatMessages?.length > 0
      ? reportChatMessages[reportChatMessages.length - 1]
      : null;

  const handleCopyShareableLink = () => {
    copyShareableLink(reportId, lastChatMessage);
  };

  const handleDoubleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  };

  const downloadAllDocsAsZip = async (docs: SingleDocResponseData[]) => {
    const zip = new JSZip();

    try {
      // Download all files and add them to zip
      const filePromises = docs.map(async (doc) => {
        const response = await fetch(doc.presignedUrl);
        const blob = await response.blob();
        zip.file(doc.document.filename, blob);
      });

      await Promise.all(filePromises);

      // Generate filename from report name and ID
      const sanitizedReportName = editableReportName
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      const zipFilename = `${sanitizedReportName}_${reportId}_documents.zip`;

      // Generate and save the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, zipFilename);
    } catch (error) {
      console.error("Error creating zip file:", error);
      toast.error("Failed to download documents. Please try again.");
    }
  };

  useEffect(() => {
    if (artifacts && artifacts.length > 0) {
      const hasInsights = artifacts.some(
        (artifact) =>
          artifact.status === "started" || artifact.status === "complete"
      );
      setShowQumisInsights(hasInsights);
    }
  }, [artifacts]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Tabs
        defaultValue="coverageReport"
        className="mt-8 h-full max-h-[60vh] w-full"
      >
        <div className="custom-heading-darker custom-shadcn-components-sharpe p-3 px-4">
          <div className="flex items-center justify-between">
            {isEditing ? (
              <Input
                value={editableReportName}
                onChange={handleChange}
                onBlur={handleSave}
                onKeyDown={handleKeyDown}
                onDoubleClick={handleDoubleClick}
                autoFocus
                className="text-xl"
              />
            ) : (
              <>
                <h2
                  className="text-black-500 cursor-pointer text-xl font-semibold"
                  onClick={() => setIsEditing(true)}
                >
                  {displayReportName || "Report Name - Empty"}
                </h2>
                <Pencil
                  className="h-4 w-4 cursor-pointer text-gray-400 transition-colors duration-200 ease-in-out hover:text-gray-600"
                  onClick={() => setIsEditing(true)}
                />
              </>
            )}
          </div>
          <div className="flex items-center">
            <TabsList className="mt-5 ">
              <TabsTrigger
                value="coverageReport"
                className="transform transition-transform duration-300 hover:scale-105"
              >
                Report
              </TabsTrigger>
              <TabsTrigger
                value="docs"
                className="transform transition-transform duration-300 hover:scale-105"
              >
                Documents
              </TabsTrigger>
              {showQumisInsights && (
                <>
                  <TabsTrigger
                    value="insights"
                    className="flex w-full max-w-xs transform items-center justify-center bg-gradient-to-r from-primary/[0.03] via-primary/[0.05] to-transparent px-4 py-2 transition-transform duration-300 hover:scale-105"
                  >
                    <img
                      src="/images/q-icon.png"
                      alt="Qumis"
                      className="mr-2 mt-0.5 h-5 w-auto opacity-90"
                    />
                    <span className="font-medium tracking-wider text-primary/70">
                      Qumis Insights
                    </span>
                  </TabsTrigger>
                </>
              )}
            </TabsList>
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />

            <div className="ml-auto flex flex-col items-start">
              <ReportHorizontalMenu
                onRegenerate={handleRegenerateDialogOpen}
                onExportPDF={handleExportPDF}
                onCopyShareableLink={handleCopyShareableLink}
                onAddFiles={handleAddFilesDialogOpen}
                rating={rating}
                onRating={handleRating}
                reportType={reportType}
              />
            </div>
          </div>
        </div>

        <TabsContent
          className="custom-shadcn-components-sharpe relative h-[calc(100vh-15rem)] w-full overflow-y-auto"
          value="coverageReport"
        >
          <div className="w-full">
            <StrictMode>
              <MilkdownProvider>
                <div className="flex w-full flex-col overflow-scroll">
                  {reportType === "coverage_checklist" ? (
                    <CustomDocViewer
                      fileUrl={coverageReportUrl || ""}
                      fileType={
                        coverageReportUrl
                          ? coverageReportUrl.split("?")[0].split(".").pop() ||
                            ""
                          : ""
                      }
                    />
                  ) : (
                    <>
                      <CoverageReportEditor
                        markdown={fullCoverageReport}
                        openReference={openReference}
                        selectedText={selectedText}
                        reportId={reportId}
                        reportType={reportType}
                      />
                      <BottomSheet
                        open={open}
                        onDismiss={() => setOpen(false)}
                        snapPoints={({ maxHeight }) => [
                          maxHeight * 0.4,
                          maxHeight * 0.8,
                        ]}
                        expandOnContentDrag
                        header={
                          <div className="border-b p-4">
                            <h3 className="text-lg font-semibold">
                              Additional Content
                            </h3>
                          </div>
                        }
                      >
                        <div className="p-4">
                          {/* Add your bottom sheet content here */}
                          <p>This is the bottom sheet content</p>
                        </div>
                      </BottomSheet>
                    </>
                  )}
                </div>
              </MilkdownProvider>
            </StrictMode>
          </div>
        </TabsContent>
        <TabsContent
          value="docs"
          className="custom-heading-darker custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
        >
          <div className="w-full p-4">
            <div className="mb-4 flex justify-end">
              <Button
                onClick={() => downloadAllDocsAsZip(docs)}
                className="flex items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mr-1"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                  <polyline points="7 10 12 15 17 10" />
                  <line x1="12" y1="15" x2="12" y2="3" />
                </svg>
                Download All Documents
              </Button>
            </div>
            {docs.length > 1 ? (
              <DocumentAccordion docs={docs} />
            ) : (
              <DocumentCard doc={docs[0]} />
            )}
          </div>
        </TabsContent>
        {
          <>
            <TabsContent
              value="insights"
              className="custom-heading-darker custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
            >
              <ReportQumisInsights
                artifacts={artifacts || []}
                reportId={reportId}
              />
            </TabsContent>
          </>
        }
      </Tabs>

      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Report Feedback</DialogTitle>
            <DialogDescription>
              How can we improve our report to better meet your needs?
            </DialogDescription>
          </DialogHeader>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="Type in your suggestions for improving this report."
          />
          <DialogFooter>
            <Button
              onClick={handleFeedbackSubmit}
              className="border border-black bg-white text-black hover:bg-black hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
              disabled={!feedbackText.trim()}
            >
              Submit Feedback
            </Button>
            <Button
              onClick={handleRegenerateReport}
              className="border border-black bg-white text-black hover:bg-black hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
              disabled={!feedbackText.trim()}
            >
              Re-generate Report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showRegenerateDialog}
        onOpenChange={handleRegenerateDialogClose}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Re-Generate Report</DialogTitle>
            <DialogDescription>
              How can we improve our report to better meet your needs?
            </DialogDescription>
          </DialogHeader>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="Type in your suggestions for improving this report."
          />
          <DialogFooter>
            <Button
              onClick={() => setShowRegenerateDialog(false)}
              className="border border-black bg-white text-black hover:bg-black hover:text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegenerateReport}
              className="border border-black bg-white text-black hover:bg-black hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
              disabled={!feedbackText.trim()}
            >
              Re-generate Report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddFilesDialog}
        onOpenChange={(open) => {
          setAddFilesDialogOpen(open);
        }}
      >
        <DialogContent className="max-h-[90vh] overflow-y-auto sm:max-w-[900px]">
          <DialogHeader>
            <DialogTitle>Upload Additional File(s)</DialogTitle>
          </DialogHeader>
          <div className="flex-grow">
            <AdditionalInformation
              additionalNotes={feedbackText}
              setAdditionalNotes={setFeedbackText}
              handleUploadComplete={handleUploadComplete}
              includeUploadArea={true}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
              availableDocumentTypes={[
                DocumentTypeEnum.Fact,
                DocumentTypeEnum.Policy,
                DocumentTypeEnum.Attachment,
              ]}
              db_document_type={DocumentTypeEnum.Fact}
            />
          </div>
          <DialogFooter>
            <div className="mt-4 flex justify-end space-x-2">
              <Button variant="outline" onClick={handleAddFilesCloseDialog}>
                Cancel
              </Button>
              <Button onClick={handleSubmitAdditionalFiles}>
                Update Analysis
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Worker>
  );
}

interface DocumentAccordionProps {
  docs: SingleDocResponseData[];
}

interface DocumentCardProps {
  doc: SingleDocResponseData;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ doc }) => (
  <Card>
    <CardHeader>
      <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
    </CardHeader>
    <CardContent>
      <MainDocument
        presignedUrl={doc.presignedUrl}
        persistHighlight={() => null}
        hideDocument={false}
        highlightedContent={""}
      />
    </CardContent>
  </Card>
);

const DocumentAccordion: React.FC<DocumentAccordionProps> = ({ docs }) => (
  <Accordion type="single" collapsible className="overflow-scroll">
    {docs.map((doc, index) => (
      <AccordionItem key={index} value={`item-${index}`}>
        <AccordionTrigger>
          <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
        </AccordionTrigger>
        <AccordionContent>
          <MainDocument
            presignedUrl={doc.presignedUrl}
            persistHighlight={() => null}
            hideDocument={false}
            highlightedContent={""}
          />
        </AccordionContent>
      </AccordionItem>
    ))}
  </Accordion>
);
